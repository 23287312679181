import { Input } from 'src/design-system'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import * as React from 'react'

export type SearchFilterProps = {
  name: string
  onChange: (value: string | number) => void
  value: string | number
}

export const SearchFilter = (props: SearchFilterProps) => {
  const { name, onChange, value } = props

  const clearInput = React.useCallback(() => {
    onChange('')
  }, [onChange])

  return (
    <div className="flex-grow-0 min-w-[200px] relative">
      <div className="absolute bg-white flex inset-y-px items-center pr-3 right-0.5">
        {typeof value === 'string' && value.length > 0 ? (
          <button type="button" onClick={clearInput}>
            <X aria-hidden className="h-3.5 w-3.5" weight="bold" />
          </button>
        ) : (
          <MagnifyingGlass aria-hidden className="h-3.5 w-3.5" weight="bold" />
        )}
      </div>
      <DebouncedInput
        className="pr-8 py-1.5"
        name={name}
        onChange={onChange}
        placeholder="Search"
        value={value}
      />
    </div>
  )
}

function DebouncedInput({
  debounce = 300,
  onChange,
  value: initialValue,
  ...props
}: {
  debounce?: number
  onChange: (value: string | number) => void
  value: string | number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <Input
      {...props}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  )
}
